<template>
  <div>
    <div class="white" :style="gridWrapperStyle">
      <div :class="fColor"
           :style="keyStyle"
           @mousedown.stop="keyDown('F')"
           @mouseup.stop="keyUp('F')"
           @ontouchstart.stop="keyDown('F')">
        <div :class="gbColor"
             :style="blackKeyStyle"
             @mousedown.stop="keyDown('Gb')"
             @mouseup.stop="keyUp('Gb')"
             @ontouchstart.stop="keyDown('Gb')"/>
      </div>
      <div :class="gColor"
           :style="keyStyle"
           @mousedown.stop="keyDown('G')"
           @mouseup.stop="keyUp('G')"
           @ontouchstart.stop="keyDown('G')">
        <div :class="abColor"
             :style="blackKeyStyle"
             @mousedown.stop="keyDown('Ab')"
             @mouseup.stop="keyUp('Ab')"
             @ontouchstart.stop="keyDown('Ab')"/>
      </div>
      <div :class="aColor"
           :style="keyStyle"
           @mousedown.stop="keyDown('A')"
           @mouseup.stop="keyUp('A')"
           @ontouchstart.stop="keyDown('A')">
        <div :class="bbColor"
             :style="blackKeyStyle"
             @mousedown.stop="keyDown('Bb')"
             @mouseup.stop="keyUp('Bb')"
             @ontouchstart.stop="keyDown('Bb')"/>
      </div>
      <div :class="bColor"
           :style="keyStyle"
           @mousedown.stop="keyDown('B')"
           @mouseup.stop="keyUp('B')"
           @ontouchstart.stop="keyDown('B')"/>
    </div>
  </div>
</template>

<script>
  import PianoMixin from 'framework/ui/components/piano/PianoKeys'

  export default {
    name: 'PianoOctaveEnd',

    mixins: [PianoMixin],

    data() {
      return {
        whiteKeysLength: 4,
      }
    },

    props: {
      octave: { type: Number, required: true },
    },

    computed: {
      fColor() {
        return this.getWhiteKeyClass('F')
      },
      gbColor() {
        return this.getBlackKeyClass('Gb')
      },
      gColor() {
        return this.getWhiteKeyClass('G')
      },
      abColor() {
        return this.getBlackKeyClass('Ab')
      },
      aColor() {
        return this.getWhiteKeyClass('A')
      },
      bbColor() {
        return this.getBlackKeyClass('Bb')
      },
      bColor() {
        return this.getWhiteKeyClass('B')
      },
    },
  }
</script>
<style lang="scss" scoped>
  .clicked {
    padding-bottom: 5px;
    box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.75);
  }
</style>
