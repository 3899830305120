<template>
  <div>
    <div class="white" :style="gridWrapperStyle">
      <div :class="`${cColor}`"
           :style="keyStyle"
           @mousedown.stop="keyDown('C')"
           @mouseup.stop="keyUp('C')"
           @ontouchstart.stop="keyDown('C')">
        <div :class="dbColor"
             :style="blackKeyStyle"
             @mousedown.stop="keyDown('Db')"
             @mouseup.stop="keyUp('Db')"
             @ontouchstart.stop="keyDown('Db')"/>
      </div>
      <div :class="`${dColor}`"
           :style="keyStyle"
           @mousedown.stop="keyDown('D')"
           @mouseup.stop="keyUp('D')"
           @ontouchstart.stop="keyDown('D')">
        <div :class="ebColor"
             :style="blackKeyStyle"
             @mousedown.stop="keyDown('Eb')"
             @mouseup.stop="keyUp('Eb')"
             @ontouchstart.stop="keyDown('Eb')"/>
      </div>
      <div :class="`${eColor}`"
           :style="keyStyle"
           @mousedown.stop="keyDown('E')"
           @mouseup.stop="keyUp('E')"
           @ontouchstart.stop="keyDown('E')"/>
    </div>
  </div>
</template>

<script>
  import PianoMixin from 'framework/ui/components/piano/PianoKeys'

  export default {
    name: 'PianoOctaveStart',

    mixins: [PianoMixin],

    data() {
      return {
        whiteKeysLength: 3,
      }
    },

    props: {
      octave: { type: Number, required: true },
    },

    computed: {
      cColor() {
        return this.getWhiteKeyClass('C')
      },
      dbColor() {
        return this.getBlackKeyClass('Db')
      },
      dColor() {
        return this.getWhiteKeyClass('D')
      },
      ebColor() {
        return this.getBlackKeyClass('Eb')
      },
      eColor() {
        return this.getWhiteKeyClass('E')
      },
    },
  }
</script>

<style lang="scss" scoped>
  .clicked {
    padding-bottom: 5px;
    box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.75);
  }
</style>
