<template>
  <v-progress-circular indeterminate
                       :size="30"
                       :width="7"
                       color="secondary"/>
</template>

<script>
  export default {
    name: 'AppSpinner',
  }
</script>
