<template functional>
  <div :style="`max-height: ${props.height}`" class="container">
    <slot/>
  </div>
</template>

<script>
  export default {
    name: 'AppContainer',

    props: {
      height: { type: String, default: '70vh' },
    },
  }
</script>

<style scoped>
  .container {
    padding:    12px;
    overflow: auto;
  }
</style>
