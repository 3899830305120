import { mapActions, mapGetters } from 'vuex'
import { playNotes }              from 'framework/helpers/audio-playing/play-notes'

export default {
  computed: {
    ...mapGetters('piano-state', ['getKeyStateInOctave', 'getKeyColor']),
    ...mapGetters('settings', ['getPianoKeySizeMultiplier']),
    gridWrapperStyle: function () {
      return {
        height:              `${1.1 * this.getPianoKeySizeMultiplier}em`,
        width:               `${(0.5 * this.whiteKeysLength) * this.getPianoKeySizeMultiplier}em`,
        display:             'grid',
        gridTemplateColumns: `repeat(${this.whiteKeysLength}, ${0.5 * this.getPianoKeySizeMultiplier}em)`,
        overflow:            'auto',
        boxSizing:           'border-box',
      }
    },
    keyStyle:         function () {
      return {
        height:          `${1.05 * this.getPianoKeySizeMultiplier}em`,
        width:           `${0.5 * this.getPianoKeySizeMultiplier}em`,
        position:        'relative',
        boxSizing:       'border-box',
        border:          '.1px solid black !important',
        backgroundColor: 'white',
      }
    },
    blackKeyStyle:    function () {
      return {
        backgroundColor: 'black',
        height:          `${0.65 * this.getPianoKeySizeMultiplier}em`,
        width:           `${0.3 * this.getPianoKeySizeMultiplier}em`,
        left:            `${0.33 * this.getPianoKeySizeMultiplier}em`,
        position:        'absolute',
        zIndex:          1,
      }
    },
  },

  methods: {
    ...mapActions('piano-state', ['setPianoKeys']),
    getKey(keyName) {
      return this.getKeyStateInOctave(this.octave)[keyName]
    },
    getWhiteKeyClass(keyName) {
      return this.getKey(keyName) ? `${this.getKeyColor} clicked` : 'white'
    },
    getBlackKeyClass(keyName) {
      return this.getKey(keyName) ? `${this.getKeyColor} clicked` : 'black'
    },
    keyDown(key) {
      const notes = [`${key}${this.octave}`]
      playNotes({ notes })
      this.$store.dispatch('piano-state/autoPianoKeysPress', { keys: notes, dur: '8n' })
    },
    keyUp(key) {
      if (this.$vuetify.breakpoint.mdAndUp) {
        this.setPianoKeys({ octave: this.octave, keys: [key], val: false })
      }
    },
  },
}
